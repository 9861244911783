import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p><em parentName="p">{`Weightlifting Total`}</em></p>
    <p>{`Snatch to a 1RM`}</p>
    <p>{`Clean & Jerk to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Grettel”`}</strong></p>
    <p>{`10 Rounds for time of:`}</p>
    <p>{`3-Clean & Jerk (135/95)`}</p>
    <p>{`3-Bar Facing Burpees`}</p>
    <p>{`or`}</p>
    <p><strong parentName="p">{`“Ingred”`}</strong></p>
    <p>{`10 Rounds for time of:`}</p>
    <p>{`3-Snatch (135/95)`}</p>
    <p>{`3-Bar Facing Burpees`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      